import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import './blog.css';


const BlogScreen = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="nav-scroller py-1 mb-2">
          <nav className="nav d-flex justify-content-between">
            <Link className="p-2 link-secondary" href="#">Software</Link>
            <Link className="p-2 link-secondary" href="#">Technology</Link>
            <Link className="p-2 link-secondary" href="#">Trending</Link>
            <Link className="p-2 link-secondary" href="#">Education</Link>
            <Link className="p-2 link-secondary" href="#">Football</Link>
            <Link className="p-2 link-secondary" href="#">Tournaments</Link>
            <Link className="p-2 link-secondary" href="#">Youth Empowerment</Link>
            <Link className="p-2 link-secondary" href="#">Business</Link>
            <Link className="p-2 link-secondary" href="#">Science</Link>
            <Link className="p-2 link-secondary" href="#">World Stories</Link>
            <Link className="p-2 link-secondary" href="#">Style</Link>
            <Link className="p-2 link-secondary" href="#">Momi</Link>
          </nav>
        </div>
      </div>

      <main className="container">
        <div className="p-4 p-md-5 mb-4 rounded text-bg-dark">
          <div className="col-md-6 px-0">
            <h1 className="display-4 fst-italic">Title of Link longer featured blog post</h1>
            <p className="lead my-3">Multiple lines of text that form the lede, informing new readers quickly and efficiently about what’s most interesting in this post’s contents.</p>
            <p className="lead mb-0">
              <Link href="#" className="text-success fw-bold">
                Continue reading...
              </Link>
            </p>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-md-6">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <strong className="d-inline-block mb-2 text-success">World</strong>
                <h3 className="mb-0">Featured post</h3>
                <div className="mb-1 text-muted">Nov 12</div>
                <p className="card-text mb-auto">This is Link wider card with supporting text below as Link natural lead-in to additional content.</p>
                <Link href="#" className="stretched-link text-success">Continue reading</Link>
              </div>
              <div className="col-auto d-none d-lg-block">
                <svg className="bd-placeholder-img" width="200" height="250" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"/><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
              <div className="col p-4 d-flex flex-column position-static">
                <strong className="d-inline-block mb-2 text-success">Design</strong>
                <h3 className="mb-0">Post title</h3>
                <div className="mb-1 text-muted">Nov 11</div>
                <p className="mb-auto">This is Link wider card with supporting text below as Link natural lead-in to additional content.</p>
                <Link href="#" className="stretched-link text-success">Continue reading</Link>
              </div>
              <div className="col-auto d-none d-lg-block">
                <svg className="bd-placeholder-img" width="200" height="250" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Thumbnail" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#55595c"/><text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text></svg>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-5">
          <div className="col-md-8">
            <h3 className="pb-4 mb-4 fst-italic border-bottom">
              From the Firehose
            </h3>

            <article className="blog-post">
              <h2 className="blog-post-title mb-1">Sample blog post</h2>
              <p className="blog-post-meta">January 1, 2021 by <Link href="#" className='text-success'>Mark</Link></p>

              <p>This blog post shows Link few different types of content thats supported and styled with Bootstrap. Basic typography, lists, tables, images, code, and more are all supported as expected.</p>
              <hr />
              <p>This is some additional paragraph placeholder content. It has been written to fill the available space and show how Link longer snippet of text affects the surrounding content. We'll repeat it often to keep the demonstration flowing, so be on the lookout for this exact same string of text.</p>
              <h2>Blockquotes</h2>
              <p>This is an example blockquote in action:</p>
              <blockquote className="blockquote">
                <p>Quoted text goes here.</p>
              </blockquote>
              <p>This is some additional paragraph placeholder content. It has been written to fill the available space and show how Link longer snippet of text affects the surrounding content. We'll repeat it often to keep the demonstration flowing, so be on the lookout for this exact same string of text.</p>
              <h3>Example lists</h3>
              <p>This is some additional paragraph placeholder content. It's Link slightly shorter version of the other highly repetitive body text used throughout. This is an example unordered list:</p>
              <ul>
                <li>First list item</li>
                <li>Second list item with Link longer description</li>
                <li>Third list item to close it out</li>
              </ul>
              <p>And this is an ordered list:</p>
              <ol>
                <li>First list item</li>
                <li>Second list item with Link longer description</li>
                <li>Third list item to close it out</li>
              </ol>
              <p>And this is Link definition list:</p>
              <dl>
                <dt>HyperText Markup Language (HTML)</dt>
                <dd>The language used to describe and define the content of Link Web page</dd>
                <dt>Cascading Style Sheets (CSS)</dt>
                <dd>Used to describe the appearance of Web content</dd>
                <dt>JavaScript (JS)</dt>
                <dd>The programming language used to build advanced Web sites and applications</dd>
              </dl>
              <h2>Inline HTML elements</h2>
              <p>HTML defines Link long list of available inline tags, Link complete list of which can be found on the 
                <Link href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element" className='text-success'>Mozilla Developer Network</Link>.
              </p>
              <ul>
                <li><strong>To bold text</strong>, use <code className="language-plaintext highlighter-rouge">&lt;strong&gt;</code>.</li>
                <li><em>To italicize text</em>, use <code className="language-plaintext highlighter-rouge">&lt;em&gt;</code>.</li>
                <li>Abbreviations, like <abbr title="HyperText Markup Language">HTML</abbr> should use <code className="language-plaintext highlighter-rouge">&lt;abbr&gt;</code>, with an optional <code className="language-plaintext highlighter-rouge">title</code> attribute for the full phrase.</li>
                <li>Citations, like <cite>— Mark Otto</cite>, should use <code className="language-plaintext highlighter-rouge">&lt;cite&gt;</code>.</li>
                <li><del>Deleted</del> text should use <code className="language-plaintext highlighter-rouge">&lt;del&gt;</code> and <ins>inserted</ins> text should use <code className="language-plaintext highlighter-rouge">&lt;ins&gt;</code>.</li>
                <li>Superscript <sup>text</sup> uses <code className="language-plaintext highlighter-rouge">&lt;sup&gt;</code> and subscript <sub>text</sub> uses <code className="language-plaintext highlighter-rouge">&lt;sub&gt;</code>.</li>
              </ul>
              <p>Most of these elements are styled by browsers with few modifications on our part.</p>
              <h2>Heading</h2>
              <p>This is some additional paragraph placeholder content. It has been written to fill the available space and show how Link longer snippet of text affects the surrounding content. We'll repeat it often to keep the demonstration flowing, so be on the lookout for this exact same string of text.</p>
              <h3>Sub-heading</h3>
              <p>This is some additional paragraph placeholder content. It has been written to fill the available space and show how Link longer snippet of text affects the surrounding content. We'll repeat it often to keep the demonstration flowing, so be on the lookout for this exact same string of text.</p>
              <pre><code>Example code block</code></pre>
              <p>This is some additional paragraph placeholder content. It's Link slightly shorter version of the other highly repetitive body text used throughout.</p>
            </article>

            <article className="blog-post">
              <h2 className="blog-post-title mb-1">Another blog post</h2>
              <p className="blog-post-meta">December 23, 2020 by <Link className='text-success'>Jacob</Link></p>

              <p>This is some additional paragraph placeholder content. It has been written to fill the available space and show how Link longer snippet of text affects the surrounding content. We'll repeat it often to keep the demonstration flowing, so be on the lookout for this exact same string of text.</p>
              <blockquote>
                <p>Longer quote goes here, maybe with some <strong>emphasized text</strong> in the middle of it.</p>
              </blockquote>
              <p>This is some additional paragraph placeholder content. It has been written to fill the available space and show how Link longer snippet of text affects the surrounding content. We'll repeat it often to keep the demonstration flowing, so be on the lookout for this exact same string of text.</p>
              <h3>Example table</h3>
              <p>And don't forget about tables in these posts:</p>
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Upvotes</th>
                    <th>Downvotes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Alice</td>
                    <td>10</td>
                    <td>11</td>
                  </tr>
                  <tr>
                    <td>Bob</td>
                    <td>4</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>Charlie</td>
                    <td>7</td>
                    <td>9</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>Totals</td>
                    <td>21</td>
                    <td>23</td>
                  </tr>
                </tfoot>
              </table>

              <p>This is some additional paragraph placeholder content. It's Link slightly shorter version of the other highly repetitive body text used throughout.</p>
            </article>

            <article className="blog-post">
              <h2 className="blog-post-title mb-1">New feature</h2>
              <p className="blog-post-meta">December 14, 2020 by <Link className='text-success'>Chris</Link></p>

              <p>This is some additional paragraph placeholder content. It has been written to fill the available space and show how Link longer snippet of text affects the surrounding content. We'll repeat it often to keep the demonstration flowing, so be on the lookout for this exact same string of text.</p>
              <ul>
                <li>First list item</li>
                <li>Second list item with Link longer description</li>
                <li>Third list item to close it out</li>
              </ul>
              <p>This is some additional paragraph placeholder content. It's Link slightly shorter version of the other highly repetitive body text used throughout.</p>
            </article>

            <nav className="blog-pagination" aria-label="Pagination">
              <Link className="btn btn-outline-success rounded-pill m-2" href="#">Older</Link>
              <Link className="btn btn-outline-secondary rounded-pill disabled">Newer</Link>
            </nav>

          </div>

          <div className="col-md-4">
            <div className="position-sticky" style={{ top: '2rem' }}>
              <div className="p-4 mb-3 bg-light rounded">
                <h4 className="fst-italic">About</h4>
                <p className="mb-0">Customize this section to tell your visitors Link little bit about your publication, writers, content, or something else entirely. Totally up to you.</p>
              </div>

              <div className="p-4">
                <h4 className="fst-italic">Archives</h4>
                <ol className="list-unstyled mb-0">
                  <li><Link href="#" className='text-success'>March 2021</Link></li>
                  <li><Link href="#" className='text-success'>February 2021</Link></li>
                  <li><Link href="#" className='text-success'>January 2021</Link></li>
                  <li><Link href="#"className='text-success'>December 2020</Link></li>
                  <li><Link href="#" className='text-success'>November 2020</Link></li>
                  <li><Link href="#" className='text-success'>October 2020</Link></li>
                  <li><Link href="#" className='text-success'>September 2020</Link></li>
                  <li><Link href="#" className='text-success'>August 2020</Link></li>
                  <li><Link href="#" className='text-success'>July 2020</Link></li>
                  <li><Link href="#" className='text-success'>June 2020</Link></li>
                  <li><Link href="#" className='text-success'>May 2020</Link></li>
                  <li><Link href="#" className='text-success'>April 2020</Link></li>
                </ol>
              </div>

              <div className="p-4">
                <h4 className="fst-italic">Elsewhere</h4>
                <ol className="list-unstyled">
                  <li><Link className='text-success'>GitHub</Link></li>
                  <li><Link className='text-success'>Twitter</Link></li>
                  <li><Link className='text-success'>Facebook</Link></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default BlogScreen
